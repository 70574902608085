











import { Component, Model, Vue } from 'vue-property-decorator'

type QColor = 'primary' | 'secondary' | 'accent' | 'positive' | 'negative' | 'info' | 'warning'
export interface BannerModel {
  color?: QColor
  message?: string
  dismissable?: boolean
  action?: Action
}

interface Action {
  label: string
  fn: () => void
}

@Component
export default class extends Vue {
  @Model()
  banner!: BannerModel

  get shown (): boolean {
    return this.banner.message !== undefined && this.banner.message !== ''
  }

  get bgColor (): string {
    return `bg-${ this.banner.color }`
  }

  get isDismissable () {
    return this.banner.dismissable === undefined || this.banner.dismissable === true
  }

  async action () {
    if (this.banner.action && typeof this.banner.action.fn === 'function') {
      await this.banner.action.fn()
    }
    if (this.isDismissable) {
      this.$emit('dismissed')
    }
  }
}
