




















import { Component, Vue } from 'vue-property-decorator'
import Banner, { BannerModel } from '@/components/Banner.vue'
import firebase from 'firebase/app'
import { mapState } from 'vuex'
import Settings from '@/components/Settings.vue'

@Component({
  components: { Banner, Settings },
  computed: { ...mapState(['profile']) }
})
export default class extends Vue {
  banner: BannerModel = {}

  get headerClass () {
    return ['header']
  }

  logout () {
    return firebase.auth().signOut()
  }

}
