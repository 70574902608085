














import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: { ...mapState(['profile']) }
})
export default class extends Vue {
  activeBinding: string = ''
  event!: (ev: KeyboardEvent) => void

  disabled (binding: string): boolean {
    return this.activeBinding === binding
  }

  unbindEvent () {
    if (this.event) {
      window.removeEventListener('keydown', this.event)
    }

    this.activeBinding = ''
  }

  beforeDestroy () {
    this.unbindEvent()
  }

  bindingClicked (binding: string) {
    this.unbindEvent()
    this.activeBinding = binding

    this.event = (ev: KeyboardEvent) => {
      console.log(ev.key, ev.keyCode)
      this.$store.dispatch('profile/settings/keyMappingsUpdated', { binding, key: ev.key })
      this.unbindEvent()
    }

    window.addEventListener('keydown', this.event)
  }

  bindingToDisplay (key: string): string {
    return key.replace(/([a-z])([A-Z])/g, '$1 $2')
  }

  keyBindingKeys () {
    console.log()
    return Object.keys(this.$store.state.profile.settings.keyBindings)
  }

  onShowContrastBadgesToggle (value: boolean) {
    return this.$store.dispatch('profile/settings/showContrastBadgesToggled', value)
  }
}
